import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import DancerImage from "../components/image_dancer"

const IndexPage = () => (

  <Layout>
    <SEO title="Home" />

      <div className="big-pictures">
        <DancerImage />
      </div>

  </Layout>
)

export default IndexPage
